<template>
    <button @click="store.resetFilters" class="absolute top-4 right-4 text-xs font-bold text-primary-600">Reset</button>
    <div class="space-y-6">
        <div>
            <h2 class="text-sm uppercase font-semibold mb-3 text-gray-800">Investing Need</h2>
            <ul>
                <li v-for="option of productTypeOptions">
                    <CheckboxField :label="option.label" @input="v => store.setProductType(option.value, v)"
                        :checked="store.productType.includes(option.value)" />
                </li>
            </ul>
        </div>
        <div>
            <h2 class="text-sm uppercase font-semibold mb-3 text-gray-800">Annuity Type</h2>
            <ul>
                <li v-for="option, i of returnsOptions">
                    <CheckboxField :label="option.label" @input="v => store.setReturns(option.value, v)"
                        :checked="store.returns.includes(option.value)" :name="option.value" />
                </li>
            </ul>
        </div>
        <div>
            <h2 class="text-sm uppercase font-semibold mb-3 text-gray-800">Minimum Rating</h2>
            <div>
                <button v-for="_, i in Array(5)"
                    @click="store.setField('minimumRating', store.minimumRating == i + 1 ? 0 : i + 1)">
                    <FilledStarIcon class="w-4 h-4 text-yellow-400" v-if="i + 1 <= store.minimumRating" />
                    <HollowStarIcon class="w-4 h-4 text-yellow-400" v-if="i >= store.minimumRating" />
                </button>
            </div>
        </div>
        <div>
            <h2 class="text-sm uppercase font-semibold mb-3 text-gray-800">Popular use cases
            </h2>
            <ul>
                <li v-for="option of useCaseOptions">
                    <button
                        @click="() => store.setField('useCase', store.useCase === option.value ? null : option.value)"
                        :class="[store.useCase === option.value ? 'font-bold text-primary-600' : 'font-normal text-gray-500']"
                        class="text-sm hover:text-primary-600 cursor-pointer block mb-2 text-left leading-tight">
                        {{ option.label
                        }}
                    </button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup>
import { useQuestionnaireStore } from '../../stores/questionnaireStore'
import { productTypeOptions, returnsOptions, useCaseOptions } from '../../data/strategyList'
import { CheckBadgeIcon, StarIcon as FilledStarIcon } from '@heroicons/vue/20/solid';
import { StarIcon as HollowStarIcon, AdjustmentsHorizontalIcon } from '@heroicons/vue/24/outline';

import { CheckboxField } from '@reviseannuity/randolph-ui'

const store = useQuestionnaireStore()
</script>