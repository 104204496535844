<template>
    <div class="fixed z-50 inset-0 hidden sm:static sm:block">
        <div class="border border-gray-200 bg-white px-4 py-4 pb-10 sm:pb-4 w-full absolute bottom-0 sm:static sm:rounded-lg transition-all ease-in-out duration-500 sm:translate-y-0"
            :class="[show ? 'translate-y-0' : 'translate-y-full']">
            <button
                class="block sm:hidden bg-primary-200 font-bold border border-b-0 border-primary-300 text-primary-950 text-sm px-8 py-4 rounded-t-lg absolute bottom-full right-2"
                @click="show = !show">
                <PhoneIcon class="w-3 -mt-1 inline-block mr-1.5 -ml-2" />Talk to an expert
            </button>
            <div class="grid grid-cols-4 gap-4">
                <div class="">
                    <img src="../../assets/headshot4.jpg" class="overflow-hidden rounded-full" />
                </div>
                <div class="col-span-3">
                    <h3 class="font-bold mb-1">Talk to an expert now</h3>
                    <p class="text-gray-800 text-sm leading-4 mb-4">Our expertly trained strategists are ready to help
                        you.
                    </p>
                    <div class="grid grid-cols-2 sm:grid-cols-1 gap-1">
                        <PrimaryButton size="sm" block class="text-nowrap">
                            <PhoneIcon class="w-3 -mt-1 inline-block" /> (855) 256-4477
                        </PrimaryButton>
                        <SecondaryButton size="sm" block @click="() => modalIsOpen = true">Book a Meeting
                        </SecondaryButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ContactModal :open="modalIsOpen" @close="() => modalIsOpen = false" calLink="revise/popup-call"
        :birthdate="questionnaireStore.birthdate" strategyName=""
        :investmentAmount="questionnaireStore.investmentAmount" :state="questionnaireStore.state"
        :name="`${registrationStore.firstName} ${registrationStore.lastName}`" :email="registrationStore.email"
        :phone="registrationStore.phone" />
</template>
<script setup>
import { PhoneIcon } from '@heroicons/vue/16/solid';
import { SecondaryButton } from '@reviseannuity/randolph-ui';
import { PrimaryButton } from '@reviseannuity/randolph-ui';
import ContactModal from '../ContactModal.vue';
import { ref } from 'vue';
import { useQuestionnaireStore } from '../../stores/questionnaireStore';
import { useRegistrationStore } from '../../stores/registrationStore';

const questionnaireStore = useQuestionnaireStore()
const registrationStore = useRegistrationStore()

const show = ref(false)
const modalIsOpen = ref(false)
</script>