<template>
    <MarketplaceSignupPaywall />
    <div class="max-w-7xl mx-auto pb-8 lg:px-8">
        <div v-if="store.strategyLoadingError !== null" class="text-center py-12 max-w-md mx-auto">
            <h2 class="font-serif font-bold text-2xl sm:text-3xl mb-2">Oops! Something went wrong.</h2>
            <p class="text-lg text-gray-600 mb-2">We're sorry, something went wrong and we were unable to load
                strategies.
            </p>
            <ErrorMessage>
                {{ store.strategyLoadingError }}
            </ErrorMessage>
            <PrimaryButton @click="store.fetchStrategies" class="mt-8">Try again?</PrimaryButton>
        </div>
        <SLPLoader v-if="store.loadingStrategies" />
        <div v-else-if="store.strategies.length">
            <div class="sm:hidden">
                <SidebarHelp />
            </div>
            <MobileFilterDrawer :sidebarOpen="sidebarOpen" @toggle="() => sidebarOpen = !sidebarOpen">
                <FilterList />
                <SidebarTips />
            </MobileFilterDrawer>
            <div class="grid lg:grid-cols-8 lg:gap-12">
                <div class="hidden lg:block lg:col-span-2">
                    <div class="lg:sticky top-20">
                        <div class="px-8 py-6 bg-white rounded-lg shadow mb-8 relative">
                            <FilterList />
                        </div>
                    </div>
                </div>
                <div class="lg:col-span-4">
                    <div
                        class="text-xs flex justify-between items-center gap-4 mb-4  bg-gray-100 z-20 sticky top-20 lg:top-20 pb-2">
                        <div class="flex items-center gap-2">
                            <SecondaryButton class="!px-2 !py-1.5 lg:hidden" @click="sidebarOpen = true">
                                <AdjustmentsHorizontalIcon class="text-gray-700 w-4 h-4" />
                            </SecondaryButton>
                            <span class="text-gray-600">
                                <strong>{{ store.sortedResults.length + 1 }}</strong> of 8,104
                                strategies
                            </span>
                        </div>
                        <SelectField :options="sortByOptions" :value="store.sortBy"
                            @input="v => store.setField('sortBy', v)" />
                    </div>
                    <button @click="showStrategyDetails(store.strategies[0])" class="w-full">
                        <StrategyCard :title="store.strategies[0].name"
                            :description="store.strategies[0].shortDescription" :indexes="store.strategies[0].indices"
                            :strategyFee="store.strategies[0].strategyFee"
                            :surrenderPeriod="store.strategies[0].surrenderPeriod"
                            :liquidityProvision="formatPercentage(store.strategies[0].underlyingProduct.liquidityProvision)"
                            :rate="store.strategies[0].historicalRate" :bestFit="true"
                            :rolloverApproved="store.strategies[0].rolloverApproved" :tags="store.strategies[0].tags"
                            :rating="store.strategies[0].underlyingProduct.insurer.rating"
                            :reviews="store.strategies[0].reviews" :volatility="store.strategies[0].volatility"
                            :minVolatility="store.minVolatility" :maxVolatility="store.maxVolatility"
                            :strategyType="store.strategies[0].type" :productFee="store.strategies[0].productFee"
                            :assetGrowthRate="store.strategies[0].assetGrowthRate"
                            :incomeGrowthRate="store.strategies[0].incomeGrowthRate" :bonus="store.strategies[0].bonus"
                            :carrierName="store.strategies[0].underlyingProduct.insurer.name" />

                    </button>
                    <div v-if="store.sortedResults" class=" space-y-6 mt-12">
                        <div v-for="strategy in store.sortedResults" :key="strategy.id">
                            <button @click="showStrategyDetails(strategy)" class="w-full">
                                <StrategyCard :title="strategy.name" :description="strategy.shortDescription"
                                    :indexes="strategy.indices" :strategyFee="strategy.strategyFee"
                                    :surrenderPeriod="strategy.surrenderPeriod"
                                    :rolloverApproved="strategy.rolloverApproved"
                                    :liquidityProvision="formatPercentage(strategy.underlyingProduct.liquidityProvision)"
                                    :rate="strategy.historicalRate" :tags="strategy.tags" :reviews="strategy.reviews"
                                    :rating="strategy.underlyingProduct.insurer.rating"
                                    :volatility="strategy.volatility" :minVolatility="store.minVolatility"
                                    :maxVolatility="store.maxVolatility" :strategyType="strategy.type"
                                    :productFee="strategy.productFee" :assetGrowthRate="strategy.assetGrowthRate"
                                    :incomeGrowthRate="strategy.incomeGrowthRate" :bonus="strategy.bonus"
                                    :carrierName="strategy.underlyingProduct.insurer.name" />
                            </button>
                        </div>
                    </div>
                    <div class="text-center py-16 text-gray-800">
                        <p class="text-xl font-semibold">
                            Don't see what you're looking for?
                        </p>
                        <p class="mb-8">We have access to all products across the entire insurance market.</p>
                        <p class="mb-2">
                            <a href="https://cal.com/revise/popup-call" target="_blank"
                                class="underline text-primary-600">Book a
                                meeting
                                with our
                                experts</a>
                        </p>
                        <p class="mb-2">
                            or
                        </p>
                        <p>
                            <a href="tel:8552564477" class="underline text-primary-600">(855)
                                256-4477</a>
                        </p>
                    </div>
                </div>
                <div class="hidden lg:col-span-2 lg:block">
                    <div class="lg:sticky top-20 space-y-8">
                        <SidebarTips />
                        <SidebarHelp />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { useQuestionnaireStore } from '../../stores/questionnaireStore';
import { event } from 'vue-gtag'
import { QUESTIONNAIRE_STEPS, formatPercentage } from '../../utils'
import { useRouter } from 'vue-router';
import StrategyCard from '../StrategyCard.vue';
import { PrimaryButton, ErrorMessage, SelectField, SecondaryButton } from '@reviseannuity/randolph-ui';
import { AdjustmentsHorizontalIcon } from '@heroicons/vue/24/outline';
import SLPLoader from '../SLPLoader.vue';
import { sortByOptions, } from '../../data/strategyList';
import { MobileFilterDrawer, FilterList, SidebarTips, SidebarHelp } from '../strategyListSections';
import MarketplaceSignupPaywall from './MarketplaceSignupPaywall.vue';

const store = useQuestionnaireStore();
const router = useRouter()
const sidebarOpen = ref(false)

const chatbaseBubble = document.getElementById('chatbase-bubble-button')

let exitPopupTimeout

onMounted(async () => {
    store.setStep(QUESTIONNAIRE_STEPS.STRATEGY_LIST)
    event('page_view', {
        page_title: 'Master App Questionnaire - Marketplace - Recommendations',
        page_location: window.location.href,
        page_path: window.location.pathname
    })

    if (chatbaseBubble) chatbaseBubble.style.display = 'block';

    if (!store.strategies.length) {
        if (!store.state || !store.age) {
            store.strategyLoadingError = 'Please complete the questionnaire to view strategies.'
        } else {
            await store.fetchStrategies()

            event('slp_loaded', {
                best_fit: store.strategies[0]?.name
            })
        }
    }

    exitPopupTimeout = setTimeout(() => {
        if (store.enableExitPopup && !store.dontShowPopupAgain) {
            store.setField('showExitPopup', true)
        }
    }, 15000)
})

watch(() => [store.sortBy, store.minimumRating, store.productType, store.useCase, store.returns], () => {
    store.sortAndFilter()
})

onUnmounted(() => {
    if (chatbaseBubble) chatbaseBubble.style.display = 'none';
    clearTimeout(exitPopupTimeout)
})

const showStrategyDetails = (strategy) => {
    router.push(`strategy/${strategy.id}?ref=slp`)
    store.nextStep()
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
}
</script>