<template>
    <Modal :open="store.showPaywall" :title="submitted ? '' : `Let's get you signed up.`"
        :body="submitted ? '' : `Sign up to view your quotes immediately.`"
        :button2="submitted ? '' : `See my strategies`" :button2-disabled="!valid" @click:button-2="submit"
        :button2Loading="submitting" blurBg centerOnMobile>
        <template #body>
            <form class="grid md:grid-cols-2 gap-4 my-8" v-if="!submitted">
                <TextField label="First Name" class="" required :value="firstName" @input="v => firstName = v" />
                <TextField label="Last Name" class="" required :value="lastName" @input="v => lastName = v" />
                <TextField label="Email Address" class="col-span-full" required :value="email" @input="v => email = v"
                    :error="emailError" />
                <PhoneField label="Phone" class="col-span-full" required :value="phone" @input="v => phone = v"
                    :error="phoneError" />
                <HelperText class="text-xs col-span-full -mt-3">Standard message and data rates may apply.</HelperText>
                <div class="col-span-">
                </div>
                <CheckboxField label="I consent to receive texts and emails from Revise." class="col-span-full" required
                    :value="consent" @input="v => consent = v" />
                <ErrorMessage v-if="errorMessages" class="col-span-full">{{ errorMessages }}</ErrorMessage>
            </form>
            <div v-if="submitted">
                <div class="text-center my-12">
                    <CheckCircleIcon class="inline-block w-20 h-20 text-green-600" />
                    <h2 class="text-xl font-bold mt-4">Registration successful</h2>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script setup>
import { CheckboxField, ErrorMessage, HelperText, PhoneField, TextField } from '@reviseannuity/randolph-ui';
import Modal from '../Modal.vue';
import { watch, ref } from 'vue';
import { useQuestionnaireStore } from '../../stores/questionnaireStore';
import { CheckCircleIcon } from '@heroicons/vue/20/solid';

const store = useQuestionnaireStore()

const firstName = ref('')
const lastName = ref('')
const email = ref('')
const emailError = ref('')
const phone = ref(0)
const phoneError = ref('')
const consent = ref(false)
const valid = ref(false)
const errorMessages = ref()
const submitting = ref(false)
const submitted = ref(false)

watch([firstName, lastName, email, phone, consent], () => {
    valid.value = firstName.value && lastName.value && consent.value && !phoneError.value && !emailError.value
})

watch([email, phone], () => {
    if (email.value.length > 0 && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.value)) {
        emailError.value = 'Email is invalid'
    } else {
        emailError.value = ''
    }

    if (phone.value && !/^(?!555)(?!(\d)\1{9})\d{10}$/.test(phone.value.toString())) {
        phoneError.value = 'Phone is invalid'
    } else {
        phoneError.value = ''
    }
}, { immediate: true })

const submit = async () => {
    submitting.value = true
    errorMessages.value = ''
    const errors = validate()

    if (!errors.length) {
        try {
            const queryParams = new URLSearchParams({
                firstName: firstName.value,
                lastName: lastName.value,
                email: email.value,
                phone: phone.value,
                state: store.state,
                zip: store.zipCode,
                investmentAmount: store.investmentAmount,
                age: store.age,
                birthdate: new Date(store.birthdate).toISOString().split('T')[0]
            })
            const res = await fetch(`https://hooks.zapier.com/hooks/catch/7043155/2somg1x/?${queryParams.toString()}`)

            if (res.ok) {
                submitted.value = true
                setTimeout(() => {
                    store.setField('showPaywall', false)
                    store.setField('enableExitPopup', true)
                }, 1000)
            }
        } catch (error) {
            errorMessages.value = error.message
        }
    } else {
        errorMessages.value = errors.map(e => e.message).join('. ')
    }
    submitting.value = false
}

const validate = () => {
    const errors = []

    if (!firstName.value) {
        errors.push({ ref: firstNameError, message: 'First name is required' })
    }

    if (!lastName.value) {
        errors.push({ ref: lastNameError, message: 'Last name is required' })
    }

    if (!email.value) {
        errors.push({ ref: emailError, message: 'Email is required' })
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.value)) {
        errors.push({ ref: emailError, message: 'Email is invalid' })
    }

    if (!phone.value) {
        errors.push({ ref: phoneError, message: 'Phone is required' })
    }

    if (!/\d{10}$/.test(phone.value.toString())) {
        errors.push({ ref: phoneError, message: 'Phone is invalid' })
    }

    if (!consent.value) {
        errors.push({ ref: consentError, message: 'You must consent to receive texts and emails from Revise.' })
    }

    return errors
}

</script>