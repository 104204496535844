<template>
    <div class="rounded-xl shadow-xl overflow-hidden bg-gray-900 w-full">
        <div v-if="!videoSwap" class="w-full relative group cursor-pointer" @click="videoSwap = true">
            <PlayCircleIcon
                class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-primary-600 opacity-75 w-24 group-hover:opacity-100 pointer-events-none" />
            <img :src="videoCover" alt="YouTube Video Cover" class="w-full pointer-events-none" width="1920"
                height="1080" loading="lazy" />
        </div>
        <div v-else>
            <iframe class="w-full aspect-video" :src="`https://www.youtube.com/embed/${youtubeId}`"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
    </div>
</template>

<script setup>
import { defineProps, ref } from 'vue';
import { PlayCircleIcon } from '@heroicons/vue/20/solid';

defineProps({
    youtubeId: String,
    videoCover: String
});

const videoSwap = ref(false)
</script>