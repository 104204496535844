<template>
    <QuestionnaireSection title="When would you like to receive income?" class="max-w-lg" @click="handleContinue"
        :disableNext="!store.validateStep(QUESTIONNAIRE_STEPS.TIMELINE)">
        <div class="grid gap-4 mb-6">
            <button v-for="option of options" @click="selectOption(option.value)"
                class="text-left rounded-lg border-2 p-6" :class="[store.timeline == option.value ? 'border-primary-600 bg-primary-50 focus:outline-none' :
                    'border-gray-300 bg-white']">
                <div class="-mt-2 mb-1">
                    <StrategyTypeTag :type="option.value === 'immediately' ? 'spia' : 'dia'" />
                </div>
                <h3 class="text-xl sm:text-2xl font-bold font-serif tracking-tight mb-2"
                    :class="store.timeline == option.value ? 'text-primary-600' : 'text-gray-800'">{{ option.heading }}
                </h3>
                <p class="text-normal leading-tight"
                    :class="store.timeline == option.value ? 'text-primary-900' : 'text-gray-800'">{{
                        option.body
                    }}</p>
            </button>
        </div>
    </QuestionnaireSection>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import QuestionnaireSection from './QuestionnaireSection.vue';
import { useQuestionnaireStore } from '../../stores/questionnaireStore';
import { QUESTIONNAIRE_STEPS } from '../../utils';
import { useRouter } from 'vue-router';
import StrategyTypeTag from '../StrategyTypeTag.vue';
import { ArrowDownTrayIcon, ArrowUpRightIcon } from '@heroicons/vue/16/solid';
import { event } from 'vue-gtag';

const router = useRouter()
const store = useQuestionnaireStore();

const options = ref([
    {
        pill: 'SPIA',
        value: 'immediately',
        heading: 'Immediately or within 1 year',
        body: "A Single Premium Immediate Annuity (SPIA) is opened with a one-time lump sum payment in exchange for a guaranteed, immediate or near-term income stream.",

    },
    {
        pill: 'Deferred Income Annuity',
        value: 'future',
        heading: 'After 1 year or more',
        body: "A Deferred Income Annuity (DIA) involves making a one-time payment that grows over time, allowing you to receive a larger lifetime income starting at a future date.",
    },
])

onMounted(() => {
    store.setStep(QUESTIONNAIRE_STEPS.TIMELINE)
})

const selectOption = (value) => {
    store.setField('timeline', value)
}

const handleContinue = () => {
    event('timeline_set', { timeline: store.timeline })
    router.push(store.nextStep())
}
</script>