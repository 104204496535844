<template>
    <div class="sm:flex justify-center sm:gap-6 items-start mx-auto w-auto">
        <QuestionnaireSection class="max-w-xl !mx-0 sm:mb-0 mb-4" noButton>
            <div class="flex items-center gap-2 mb-4">
                <BestFitBadge pill />
                <StrategyTypeTag type="spia" color />
            </div>
            <h2 class="font-serif font-bold text-2xl sm:text-3xl mb-2">
                You're a great fit for a SPIA.
            </h2>
            <p class="mb-4">We think a Single Premium Immediate Annuity (SPIA) is the best fit. SPIAs are quoted to our
                clients live as rates and terms can fluctuate daily based on various financial factors, such as interest
                rates, economic conditions, and insurer strategies.</p>
            <p class="mb-8">Our agents have access to the entire market and can
                provide the most accurate and up-to-date information tailored to your specific financial situation and
                objectives.</p>
            <p class="text-center mb-2 text-lg">Get your SPIA quote now:</p>
            <div class="md:w-60 mx-auto">
                <div class="mb-2 flex justify-center ">
                    <a href="tel:+18552564477" class="w-full">
                        <SecondaryButton block>
                            <div class="flex items-center justify-center gap-1">
                                <PhoneIcon class="w-4 h-4 -mt-0.5" />
                                Call (855) 256-4477
                            </div>
                        </SecondaryButton>
                    </a>
                </div>
                <p class="text-center mb-2 text-sm font-semibold text-gray-600">OR</p>
                <div class="mb-2 flex justify-center">
                    <PrimaryButton block @click="showCalEmbed = true">
                        <div class="flex items-center justify-center gap-1">
                            <CalendarIcon class="-mt-0.5 w-4 h-4" />
                            Schedule a quote meeting
                        </div>
                    </PrimaryButton>
                </div>
            </div>
        </QuestionnaireSection>
        <Modal :open="showCalEmbed" @close="showCalEmbed = false" button1="Cancel"
            @click:button1="showCalEmbed = false">
            <CalEmbed calLink="revise/spia-quote" strategyName="SPIA" :state="state"
                :dob="birthdate && new Date(birthdate).toLocaleDateString()" />
        </Modal>
    </div>
</template>

<script setup>
import { CalendarIcon, PhoneIcon } from '@heroicons/vue/16/solid';
import QuestionnaireSection from './QuestionnaireSection.vue';
import { SecondaryButton, PrimaryButton } from '@reviseannuity/randolph-ui';
import CalEmbed from '../CalEmbed.vue';
import { useQuestionnaireStore } from '../../stores/questionnaireStore';
import { onMounted, ref } from 'vue';
import Modal from '../Modal.vue';
import StrategyTypeTag from '../StrategyTypeTag.vue';
import BestFitBadge from '../BestFitBadge.vue';

const store = useQuestionnaireStore()
const showCalEmbed = ref(false)

const {
    state,
    birthdate
} = store
</script>