<template>
    <TransitionRoot as="template" :show="open">
        <Dialog class="relative z-50" @close="$emit('close')">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <TransitionChild as="template" enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel
                            class="relative transform overflow-hidden rounded-lg bg-white w-full max-w-4xl text-left shadow-xl transition-all">
                            <XCircleIcon @click="$emit('close')"
                                class="absolute top-4 right-4 w-6 h-6 text-gray-500 cursor-pointer" />
                            <div class="grid md:grid-cols-8" v-if="!isBookingMeeting">
                                <div class="md:col-span-5 p-8 md:p-12 md:px-16 ">
                                    <h2 class="text-4xl md:text-5xl font-serif font-bold mb-4">Find what you're
                                        looking for?
                                    </h2>
                                    <p class="mb-4 md:mb-8">Let our licensed experts walk you through our suite of
                                        annuity
                                        products on a
                                        scheduled call. Here, we can address all of your questions and build a
                                        personalized strategy.</p>
                                    <PrimaryButton @click="isBookingMeeting = true" class="w-full md:w-auto">
                                        Schedule your free call now
                                    </PrimaryButton>
                                </div>
                                <div class="md:col-span-3">
                                    <div class="h-60 sm:h-full w-full relative">
                                        <img src="../assets/helpdesk-man.jpg" alt="Exit Popup"
                                            class="w-full h-full object-cover absolute top-0" />
                                    </div>
                                </div>
                            </div>
                            <div v-if="isBookingMeeting" class="p-8">
                                <h2 class="text-3xl font-serif font-bold mb-8">Schedule your free call now</h2>
                                <CalEmbed calLink="revise/popup-call" :investmentAmount="0" :state="store.state || ''"
                                    :dob="store.birthdate ? new Date(store.birthdate).toLocaleDateString() : ''" />
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { PrimaryButton } from '@reviseannuity/randolph-ui';
import CalEmbed from './CalEmbed.vue'
import { ref } from 'vue';
import { useQuestionnaireStore } from '../stores/questionnaireStore';
import { XCircleIcon } from '@heroicons/vue/20/solid';

defineEmits(['close'])

defineProps({
    open: Boolean
})

const store = useQuestionnaireStore()

const isBookingMeeting = ref(false)
</script>