<template>
    <div class="bg-[#f1f5f9] fixed top-0 left-0 w-full z-50">
        <div class="max-w-sm mx-auto px-4">
            <div class="pt-4 mb-4 sm:mb-6 flex flex-col items-center gap-4">
                <div class="flex justify-between w-full items-center">
                    <button @click="store.setActiveStep(store.previousStep)" class=" w-8 h-8 -m-2 rounded-full hover:bg-primary-300 flex items-center
                        justify-center" v-if="store.previousStep">
                        <ArrowLeftIcon class="w-4 h-4 text-gray-500 text-primary-600" />
                    </button>
                    <img src="../assets/logo.svg" alt="Revise Annuity Group logo" class="mx-auto h-5" />
                </div>
                <ProgressBar :progress="store.percentage" class="w-full" />
            </div>
        </div>
    </div>
    <div class="pt-20 sm:pt-24 px-4 pb-8">
        <RouterView />
    </div>

</template>

<script setup>
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useRegistrationStore, REGISTRATION_STEPS } from '../stores/registrationStore';
import { useApplicationStore } from '../stores/applicationStore';
import { useToast } from 'vue-toast-notification';
import { ArrowLeftIcon } from '@heroicons/vue/20/solid';
import { ProgressBar } from '@reviseannuity/randolph-ui'
import { RouterView } from 'vue-router';

const router = useRouter();

const {
    user: {
        auth0_id,
        phone,
        first_name,
        last_name,
        email,
        funding_type,
        funding_amount,
        rollover_call
    }
} = useApplicationStore()

const store = useRegistrationStore();

onMounted(() => {
    const registrationComplete = localStorage.getItem('registrationComplete');
    if (registrationComplete) {
        router.push({ name: 'apply' });
    }

    const data = localStorage.getItem('reviseQuestionnaireResults');
    if (data) {
        const json = JSON.parse(data);
        store.setField('strategy', json.strategy);
        store.setField('strategyName', json.strategyName);
        store.setField('state', json.state);
        store.setField('zip', json.zip);
        json.birthdate && store.setField('birthdate', new Date(json.birthdate).toDateString());
        store.setField('age', json.age);
        store.setField('investmentAmount', json.investmentAmount);
    }

    if (auth0_id) {
        store.setField('auth0Id', auth0_id);
    }

    if (phone) {
        store.setField('phone', phone);
    }

    if (first_name && last_name && email) {
        store.setField('firstName', first_name);
        store.setField('lastName', last_name);
        store.setField('email', email);
    } else {
        return
    }

    if (!funding_type) {
        return store.setActiveStep(REGISTRATION_STEPS.FUNDING)
    }

    if (!funding_amount) {
        if (funding_type === 'after-tax') {
            return store.setActiveStep(REGISTRATION_STEPS.INITIAL_PREMIUM_AMOUNT)
        }

        if (funding_type !== 'after-tax') {
            return store.setActiveStep(REGISTRATION_STEPS.QUALIFIED_INVESTMENT_AMOUNT)
        }
    }

    if (funding_type !== 'after-tax' && rollover_call) {
        return store.setActiveStep(REGISTRATION_STEPS.ROLLOVER_BOOKING)
    }

    router.push({ name: 'apply' })
})





const updateQuestionnaireResults = () => {
    let questionnaireResults = JSON.parse(localStorage.getItem('reviseQuestionnaireResults'))
    questionnaireResults = {
        ...questionnaireResults,
        name: `${firstName.value} ${lastName.value}`,
        email: email.value
    }
    localStorage.setItem('reviseQuestionnaireResults', JSON.stringify(questionnaireResults))
}
</script>