export const sortByOptions = [
    { label: 'Best Match', value: 'bestMatch' },
    { label: 'Highest Rate', value: 'highestRate' },
    { label: 'Lowest Volatility', value: 'lowestVolatility' },
    { label: 'Most Reviews', value: 'mostReviews' },
]

export const productTypeOptions = [
    { label: 'Growth', value: 'growth' },
    { label: 'Lifetime Income', value: 'income' },
    { label: 'Bonus', value: 'bonus' },
]

export const returnsOptions = [
    {
        label: 'Fixed indexed', value: 'fixed indexed', tooltip: 'Fixed indexed annuity returns are based upon the annual or biannual return of the index listed in the strategy detail page. These products are entirely protected from market loss.'
    },
    {
        label: 'Fixed', value: 'fixed', tooltip: 'Fixed annuity returns are guaranteed and and credited on an annual basis by the issuing insurance company for a specific duration of time, usually 3 to 10 years.'
    },
    {
        label: 'MYGA', value: 'myga', tooltip: ''
    }
]

export const useCaseOptions = [
    { label: '401(k) Rollover', value: '401k' },
    { label: '1035 & Annuity Replacement', value: 'annuity replacement' },
    { label: 'Guaranteed Income', value: 'guaranteed income' },
    { label: 'Yield Enhancement & Higher Returns', value: 'yield enhancement' },
    { label: 'Portfolio Hedge', value: 'portfolio hedge' },
]

export const loadingPhrases = [
    "Analyzing your responses...",
    "Searching top annuity strategies...",
    "Building your personalized experience...",
]

export const bulletPoints = [
    '100% market protected',
    'Tax-deferred growth',
    'Top-rated carriers',
    'Expert support'
]
